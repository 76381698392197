import { Box, Container, Divider, Grid, Stack, Typography, Avatar } from '@mui/material';
import React from 'react';
import ArticleLeftAd from './ArticleLeftAd';
import ArticleRightAd from './ArticleRightAd';
import LikeAndCommentSection from './LikeAndCommentSection';
import { navigateToUrl } from 'single-spa';  
import PlaceIcon from '@mui/icons-material/Place';

function Content({ article,profileImage,articleImageKey}) {

    const handleAvatarClick = (nickName) => {
        navigateToUrl(`/profile/${nickName}`);
      };

      const handleClick = () => {
        window.open(article.locationUrl, '_blank');
    };

      
    return (
        <Container maxWidth='xl' sx={{ mt: 3 }}>
            <Grid container spacing={2} justifyContent="center" ml={0.1}>
                <Grid
                    item
                    xs={12}
                    md={2}
                    mt={50}
                    sx={{
                        display: { xs: 'none', md: 'none', lg: 'block' },
                        height: '100%',
                    }}
                >
                    <Box
                        sx={{
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                        }}
                    >
                        <ArticleLeftAd />
                    </Box>
                </Grid>

                <Grid
                    item
                    xs={12}
                    md={8}
                    sx={{
                        margin: { xs: 'auto', md: '0' },
                    }}
                >
                    <Box
                        sx={{
                            minHeight: "1000px",
                            mb: 10,
                            mr: 1
                        }}
                    >
                        <Stack spacing={3}>
                            <Stack spacing={1}>
                                <Typography
                                    variant='h3'
                                    fontFamily={'serif'}
                                    sx={{ fontWeight: 700 }}
                                >
                                    {article.title}
                                </Typography>
                                <Stack spacing={2}>
                                    <Stack spacing={1} direction='row' alignItems="center">
                                        <Avatar
                                            src={profileImage}
                                            sx={{
                                                width: { xs: 30, sm: 32 },
                                                height: { xs: 30, sm: 32 },
                                                cursor: 'pointer',
                                            }}
                                        alt={article.author}
                                        onClick={() => handleAvatarClick(article.author)}
                                        />
                                        <Stack direction='column' justifyContent='center'>
                                            <Typography
                                                variant='caption'
                                                fontFamily={'serif'}
                                                color="text.secondary"
                                            >
                                                By {article.author}
                                            </Typography>
                                            <Typography
                                                variant='caption'
                                                fontFamily={'serif'}
                                                color="text.secondary"
                                            >
                                                {article.updatedAt}
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                </Stack>
                            </Stack>
                            <Divider />
                            <Typography
                                variant="h6"
                                component="div"
                                 paragraph
                                sx={{
                                    lineHeight: 1.8,
                                    textAlign: 'left',
                                    "& p": {
                                        marginBottom: "16px",
                                    },
                                    "& br": {
                                        margin: "2px 0",
                                        display: "block",
                                        content: '""',
                                    },
                                }}
                                dangerouslySetInnerHTML={{ __html: article.content }}
                            />
                        </Stack>
                        <Box
                                sx={{
                                    width: '250px',
                                    height: '150px',
                                    display: article.locationUrl ? 'flex' : 'none',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    borderRadius: 2,
                                    position: 'relative',
                                    cursor: 'pointer',
                                    overflow: 'hidden',
                                }}
                                onClick={handleClick}
                                aria-label="open external link"
                            >
                                <Box
                                    component="img"
                                    src={articleImageKey}
                                    alt="Background"
                                    sx={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover',
                                        borderRadius: 2,
                                    }}
                                />
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '100%',
                                        backgroundColor: 'rgba(0, 0, 0, 0.4)',
                                        borderRadius: 2,
                                    }}
                                />
                                <PlaceIcon
                                    sx={{
                                        position: 'absolute',
                                        bottom: '10px',
                                        left: '10px',
                                        color: 'red',
                                        height: '45px',
                                        width: '48px',
                                    }}
                                />
                            </Box>
                    </Box>
                    <Divider sx={{ mb: 5 }} />
                </Grid>

                <Grid
                    item
                    xs={12}
                    md={2}
                    mt={50}
                    sx={{
                        display: { xs: 'none', md: 'none', lg: 'block' },
                        height: '100%',
                    }}
                >
                    <Box
                        sx={{
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                        }}
                    >
                        <ArticleRightAd />
                    </Box>
                </Grid>
            </Grid>

            <Grid container>
                <Grid item xs={12}>
                    <LikeAndCommentSection
                        articleId={article.id}
                        articleLikeCount={article.likeCount}
                        articleLikes={article.likes}
                        articleComments={article.comments}
                    />
                </Grid>
            </Grid>
        </Container>
    );
}

export default Content;
