import ApiInstance from "../configs/ApiInstance";

async function GetArticleById(articleId) {
    try {
        const response = await ApiInstance.articleService.get(`${articleId}`);
        return response.data;
    } catch (error) {
        return null;
        // console.log(error)
        // throw error;
    }
}

export default GetArticleById;