import ApiInstance from "../configs/ApiInstance";

async function GetArticleImage(articleImageKey) {
    try {
       const response = await ApiInstance.userAwsService.get(`/article-image/${articleImageKey}`);
       const imageUrl = response.data.url;
       return imageUrl;
    } catch (error) {
        return null;
        // console.log("Error occurred when getting user profile image",  error)
        // throw error;
    }
}

export default GetArticleImage;
