import React, { useEffect, useState } from 'react';
import { Box } from "@mui/material";
import cover from '../images/coverImage.png';
import GetAdminData from '../services/GetAdminData';
import GetAdminImage from '../services/GetAdminImage';
import { useParams } from 'react-router-dom';

function Banner() {
    const [articleCoverImage, setArticleCoverImage] = useState(cover);
    const { category: name } = useParams(); 

    useEffect(() => {
        const fetchAdminData = async () => {
            try {
                const adminData = await GetAdminData();
                const imageKey = `${name}CoverImage`; 

                if (adminData[imageKey]) {
                    const articleCoverImageUrl = await GetAdminImage(adminData[imageKey]);
                    setArticleCoverImage(articleCoverImageUrl);
                } else {
                    // console.warn("No image found for category:", name);
                }
            } catch (error) {
                // console.error("Error occurred when getting data", error);
            }
        };

        fetchAdminData();
    }, [name]);

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                overflow: 'hidden',
                height: {
                    xs: '200px',
                    sm: '300px',
                    md: '400px',
                    lg: '400px',
                },
                width: '100%',
            }}
        >
            <Box
                component="img"
                sx={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                }}
                src={articleCoverImage}
                alt="Banner Image"
            />
        </Box>
    );
}

export default Banner;
