import ApiInstance from "../configs/ApiInstance";

async function UnLikeComment(articleId, commentId, userId) {
  try {
    const response = await ApiInstance.articleService.delete(`${articleId}/comments/${commentId}/like/${userId}`);
    return response;
  } catch (error) {
    return null;
    // console.error('Error unliking comment:', error);
    // throw error;
  }
}

export default UnLikeComment;
