import React, { useEffect, useState } from 'react';
import { Grid, Container, CssBaseline } from "@mui/material";
import Pagination from './components/Pagination'
import ArticleGrid from './components/ArticleGrid'
import GetAllArticles from './services/GetAllArticles'
import ArticleGridBanner from './components/ArticleGridBanner'
import { useParams } from 'react-router-dom';

function Screen() {

    const [allArticles, setAllArticles] = useState([]);  
    const [filteredArticles, setFilteredArticles] = useState([]); 
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const { category: name } = useParams();
    const [categoryName, setCategoryName] = useState('')

    
    useEffect(() => {
        const categoryName = name.toUpperCase()
        setCategoryName(categoryName)
      }, []);

    useEffect(() => {
        const fetchArticles = async () => {
          try {
            const { content } = await GetAllArticles();
            const approvedArticles = content.filter(article => article.status === "ACCEPTED"); 
            setAllArticles(approvedArticles);
          } catch (error) {
            // console.error("Error fetching articles:", error);
          }
        };
        fetchArticles();
      }, []);

      useEffect(() => {
        const filtered = allArticles.filter(article => 
          article.category.toLowerCase() === categoryName.toLowerCase()
        );
        setFilteredArticles(filtered);
        setTotalPages(Math.ceil(filtered.length / 10)); 
      }, [allArticles, categoryName]);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };


    return (
        <Container disableGutters maxWidth="2xl">
            <CssBaseline />
            <ArticleGridBanner categoryName={categoryName}/>
            <Grid container spacing={5}>
                <Grid item xs={12}>
                <ArticleGrid articles={filteredArticles.slice((currentPage - 1) * 10, currentPage * 10)} categoryName={categoryName} />
                </Grid>
                <Grid item xs={12} >
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange} />
                </Grid>
            </Grid>
        </Container>
    ) 
}

export default Screen