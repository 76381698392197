import React, { useState } from "react";
import {
    Box,
    Grid,
    Container,
    TextField,
    Select,
    MenuItem,
    OutlinedInput
} from "@mui/material";
import './styles/input.css';

const subcategoryOptions = {
    TRAVEL: [
        "Adventure", "Historical", "Nature", "Urban", "Cultural", "Luxury", "Backpacking", "Road Trips",
        "Beaches", "Mountains", "Wildlife", "Eco-Tourism", "Solo Travel", "Family Travel", "Hiking",
        "National Parks", "City Guides", "Travel Tips", "Budget Travel", "Travel Photography", "Festivals"
    ],
    FOOD: [
        "Recipes", "Restaurants", "Diet", "Street Food", "Baking", "Vegan", "Healthy Eating", "International Cuisine",
        "Food Trends", "Desserts", "Quick Meals", "Drinks & Cocktails", "Grilling & BBQ", "Comfort Food",
        "Gluten-Free", "Food Reviews", "Gourmet", "Keto Diet", "Meal Prep", "Farm-to-Table"
    ],
    ENTERTAINMENT: [
        "Movies", "Music", "TV Shows", "Celebrities", "Gaming", "Theater", "Books", "Festivals", "Awards Shows",
        "Concerts", "Stand-up Comedy", "Streaming Services", "Indie Films", "Pop Culture", "Anime & Manga",
        "Video Games", "Podcasts", "Broadway", "Dance", "Art Exhibits", "Fandoms"
    ],
    TECHNOLOGY: [
        "Gadgets", "Software", "AI", "Startups", "Blockchain", "Wearables", "Tech News", "Cybersecurity",
        "Smartphones", "Robotics", "Cloud Computing", "VR/AR", "Programming", "Apps", "Drones", "5G Technology",
        "Gaming Tech", "Electric Vehicles", "Internet of Things (IoT)", "Tech Reviews", "Smart Home Devices"
    ],
    HEALTH: [
        "Fitness", "Wellness", "Nutrition", "Mental Health", "Yoga", "Meditation", "Diseases", "Healthcare",
        "Workouts", "Sleep", "Diet Plans", "Supplements", "Alternative Medicine", "Healthy Aging", "Weight Loss",
        "Self-Care", "Holistic Health", "Stress Management", "Mental Wellness", "Health Tech", "Rehabilitation"
    ],
    LIFESTYLE: [
        "Fashion", "Home Decor", "Beauty", "Travel", "Hobbies", "Personal Development", "Relationships",
        "Work-Life Balance", "Minimalism", "Sustainability", "Fitness", "Social Media", "DIY Projects", 
        "Crafting", "Family & Parenting", "Wedding Planning", "Pet Care", "Luxury Lifestyle", "Personal Finance", 
        "Mindfulness", "Home Improvement"
    ],
    BUSINESS: [
        "Entrepreneurship", "Finance", "Startups", "Marketing", "E-commerce", "Leadership", "Investing", "Economy",
        "Management", "Small Business", "Corporate Culture", "Business Strategy", "Real Estate", "Cryptocurrency",
        "Stock Market", "Business News", "HR & Talent", "Sales", "Supply Chain", "Innovation", "Mergers & Acquisitions"
    ],
    SPORTS: [
        "Football", "Basketball", "Cricket", "Olympics", "Tennis", "Golf", "Rugby", "Motorsports",
        "Track & Field", "Swimming", "Boxing", "Martial Arts", "Baseball", "Hockey", "Cycling", "Winter Sports",
        "Extreme Sports", "Esports", "Fitness Competitions", "Gymnastics", "Rowing"
    ],
    ENVIRONMENT: [
        "Climate Change", "Sustainability", "Wildlife", "Conservation", "Pollution", "Renewable Energy", 
        "Forests", "Water Resources", "Endangered Species", "Global Warming", "Green Technology", "Ocean Health",
        "Environmental Policy", "Zero Waste", "Recycling", "Sustainable Fashion", "Biodiversity", "Carbon Footprint", 
        "Air Quality", "Ecology", "Environmental Activism"
    ],
    NEWS: [
        "Politics", "World News", "Local News", "Business News", "Tech News", "Breaking News", "Weather", 
        "Current Events", "Economy", "Elections", "Crime", "International Relations", "Health News", 
        "Environment News", "Legal Affairs", "Education News", "Science & Research", "Human Rights", 
        "Government", "War & Conflict", "Crisis Response"
    ],
    BOOKS: [
        "Fiction", "Non-Fiction", "Biographies", "Self-Help", "Fantasy", "Science Fiction", "Mystery",
        "Thriller", "Romance", "Historical Fiction", "Young Adult", "Children's Books", "Graphic Novels",
        "Poetry", "Classic Literature", "Cookbooks", "Travel Literature", "Business Books", "Motivational",
        "Literary Fiction", "Audiobooks"
    ]
};

function Filter({ onSearch, onTagChange, selectedCategory }) {
    const [searchQuery, setSearchQuery] = useState("");
    const [selectedTag, setSelectedTag] = useState("Default");

    const handleSearchChange = (event) => {
        const query = event.target.value;
        setSearchQuery(query);
        onSearch(query);
    };

    const handleTagChange = (event) => {
        const {
            target: { value },
        } = event;
        setSelectedTag(value);
        onTagChange(value === "Default" ? "" : value);
    };

    const tagOptions = selectedCategory && subcategoryOptions[selectedCategory.toUpperCase()] ? subcategoryOptions[selectedCategory.toUpperCase()] : [];

    return (
        <Container sx={{ mb: 4 }}>
            <Grid container spacing={2} justifyContent="center">
                <Grid item xs={12} md={6}>
                    <TextField
                        className="gradient-textfield"
                        value={searchQuery}
                        onChange={handleSearchChange}
                        placeholder="Search"
                        variant="outlined"
                        fullWidth
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        <Select
                            value={selectedTag}
                            onChange={handleTagChange}
                            displayEmpty
                            input={<OutlinedInput />}
                            renderValue={(selected) => selected === "Default" ? <em>All Articles</em> : selected}
                            className="gradient-select"
                            sx={{
                                width: "200px",
                                height: "55px",
                            }}
                        >
                            <MenuItem value="Default" disabled>
                                Tags
                            </MenuItem>
                            <MenuItem value="Default">All Articles</MenuItem>
                            {tagOptions.map((tag) => (
                                <MenuItem key={tag} value={tag.toUpperCase()}>
                                    {tag}
                                </MenuItem>
                            ))}
                        </Select>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
}

export default Filter;
