import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import GetAdminData from '../services/GetAdminData'
import GetAdminImage from '../services/GetAdminImage'

function ArticleLeftAd() {
    const [leftAd, setLeftAd] = useState();

    useEffect(() => {
        const fetchAdminData = async () => {
            try {
                const adminData = await GetAdminData();
                const leftAdUrl = await GetAdminImage(adminData.articleLeftAd);
                setLeftAd(leftAdUrl);
            } catch (error) {
                // console.error("Error occurred when getting data", error);
            }
        };
        fetchAdminData();
    }, []);

    

    return (
        <Box
            component="img"
            sx={{
                height: '500px',
                width: '200px',
                objectFit: 'cover',
                display: 'block',
            }}
            src={leftAd}
        />
    );
}

export default ArticleLeftAd;
