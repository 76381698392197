import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Screen from './Screen';
import Article from './components/Article'

function App() {
  return (
    <Router>
        <Routes>
            <Route path='/articles/:category' element={<Screen/>} />
            <Route path='/articles/:category/:id' element={<Article/>} />
        </Routes>
    </Router>
  )
}

export default App