import ApiInstance from "../configs/ApiInstance";

async function AddLike(articleId, id) {
  try {
    const userId = id;
    const response = await ApiInstance.articleService.put(`${articleId}/likes`, {
        userId,
        });
     return response;
  } catch (error) {
    return null;
    // console.log(error);
    // throw error;
  }
}

export default AddLike;
