import {
  Avatar,
  Box,
  Container,
  IconButton,
  Snackbar,
  Stack,
  Typography,
  Alert,
  Grid,
} from '@mui/material';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import React, { useEffect, useState } from 'react';
import LikeComment from '../services/LikeComment';
import UnLikeComment from '../services/UnLikeComment';
import FetchProfileImage from '../services/FetchProfileImage';
import { navigateToUrl } from 'single-spa';  

function Comment({ data = [], articleId }) {
  const [userId, setUserId] = useState(null);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const [comments, setComments] = useState(data);
  const [profileImages, setProfileImages] = useState({});

  useEffect(() => {
    const storedUserId = sessionStorage.getItem('userId');
    if (storedUserId) {
      setUserId(storedUserId);
    }
  }, []);

  useEffect(() => {
    setComments(data);
    fetchProfileImagesForComments(data);
  }, [data]);

  const fetchProfileImagesForComments = async (commentsData) => {
    const images = {};
    try {
      await Promise.all(
        commentsData.map(async (comment) => {
          if (comment.profileImage) {
            const imageUrl = await FetchProfileImage(comment.profileImage);
            images[comment.id] = imageUrl;
          }
        })
      );
      setProfileImages(images);
    } catch (error) {
      // console.error('Error fetching profile images:', error);
    }
  };

  const handleLike = async (commentId) => {
    if (!userId) {
      setError('Please log in to like this comment.');
      setOpen(true);
      return;
    }

    const hasLiked = checkIfUserLiked(commentId);

    try {
      if (hasLiked) {
        await UnLikeComment(articleId, commentId, userId);
        setComments((prevComments) =>
          prevComments.map((comment) =>
            comment.id === commentId
              ? {
                  ...comment,
                  likeCount: comment.likeCount - 1,
                  commentLikes: comment.commentLikes.filter((like) => like.userId !== userId),
                }
              : comment
          )
        );
      } else {
        await LikeComment(articleId, commentId, userId);
        setComments((prevComments) =>
          prevComments.map((comment) =>
            comment.id === commentId
              ? {
                  ...comment,
                  likeCount: comment.likeCount + 1,
                  commentLikes: [...comment.commentLikes, { userId }],
                }
              : comment
          )
        );
      }
    } catch (error) {
      // console.error('Error toggling like:', error);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const checkIfUserLiked = (commentId) => {
    const comment = comments.find((comment) => comment.id === commentId);
    return comment ? comment.commentLikes.some((like) => like.userId === userId) : false;
  };

  const handleAvatarClick = (nickName) => {
    navigateToUrl(`/profile/${nickName}`);
  };

  return (
    <Container maxWidth="lg">
      <Box>
        <Snackbar
          open={open}
          autoHideDuration={3000}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
            {error}
          </Alert>
        </Snackbar>

        {comments.map((comment) => {
          const hasLiked = checkIfUserLiked(comment.id);
          const profileImage = profileImages[comment.id] || comment.profileImage;

          return (
            <Grid container spacing={2} key={comment.id} sx={{ mb: 3 }}>
              <Grid item xs={2} sm={1}>
                <Avatar
                  src={profileImage}
                  sx={{
                    width: { xs: 55, sm: 64 },
                    height: { xs: 55, sm: 64 },
                    mt: 3,
                    cursor: 'pointer',  
                  }}
                  alt={comment.nickName}
                  onClick={() => handleAvatarClick(comment.nickName)}  
                />
              </Grid>
              <Grid item xs={10} sm={11}>
                <Box
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    minHeight: '100px',
                  }}
                >
                  <Stack spacing={1}>
                    <Typography variant="body2" fontWeight={550} fontSize={{ xs: '0.9rem', sm: '1rem' }}>
                      {comment.nickName}
                    </Typography>
                    <Typography variant="body2" fontSize={{ xs: '0.8rem', sm: '1rem' }}>
                      {comment.comment}
                    </Typography>
                  </Stack>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                    <Typography fontSize={'0.7rem'} color="gray">
                      {new Date(comment.updatedAt).toLocaleDateString()}
                    </Typography>
                    <Box sx={{ display: 'flex' }}>
                      <IconButton
                        onClick={() => handleLike(comment.id)}
                        disableRipple={true}
                        sx={{
                          bgcolor: 'transparent',
                          ':hover': {
                            bgcolor: 'transparent',
                            transition: 'none',
                          },
                        }}
                      >
                        <ThumbUpIcon color={hasLiked ? 'primary' : 'inherit'} />
                        <Typography sx={{ ml: 1 }} fontSize={{ xs: '0.7rem', sm: '1rem' }}>
                          {comment.likeCount}
                        </Typography>
                      </IconButton>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          );
        })}
      </Box>
    </Container>
  );
}

export default Comment;
