import axios from 'axios'
import configs from './endpoints.json'

const articleService = axios.create({
    baseURL: `${configs.articleService}`,
    headers: {
        'Content-Type': 'application/json'
    }
})

const userAwsService = axios.create({
    baseURL: `${configs.userAwsService}`
})

const adminService = axios.create({
    baseURL: `${configs.adminService}`
})

const userService = axios.create({
    baseURL: `${configs.userService}`
})

const ApiInstance = {
    articleService,
    userAwsService,
    adminService,
    userService
}

export default ApiInstance;